<template>
  <div class="create-group">
    <div
      v-if="isCreateSubGroup"
      class="px-[14px] py-[10px] border-b-1 border-solid border-[#2e2e2e] flex"
    >
      <img
        :src="
          require(`@/static/img/group-management/${
            WARNING_LEVEL[parent.warningLevel - 1].img
          }`)
        "
        alt="priority"
      />
      <span class="text-[22px] text-ems-gray300 ml-4">{{ parent.name }}</span>
    </div>
    <a-form
      :model="formState"
      layout="vertical"
      class="mx-auto"
      @finish="handleSubmit"
      :rules="rules"
    >
      <span
        class="text-base font-semibold text-ems-gray200 block mb-[15px] mt-[22px]"
        >{{ t('group-management.title-info') }}</span
      >
      <a-form-item name="name">
        <span class="text-sm text-ems-gray500 mb-1">{{
          t('group-management.name')
        }}</span>
        <a-input
          type="text"
          v-model:value="formState.name"
          :placeholder="t('group-management.name')"
          class="ant-input-sm"
          :maxlength="100"
          showCount
          allowClear
        />
      </a-form-item>
      <a-form-item name="description">
        <span class="text-sm text-ems-gray500 mb-1">{{
          t('group-management.desc')
        }}</span>
        <a-input
          type="text"
          v-model:value="formState.description"
          class="ant-input-sm"
          :placeholder="t('group-management.desc')"
          :maxlength="255"
          showCount
          allowClear
        />
      </a-form-item>
      <a-form-item class="!mt-[45px]" name="warningLevel">
        <span
          class="text-base font-semibold text-ems-gray200 mb-[15px] block"
          >{{ t('group-management.priority') }}</span
        >

        <div class="flex gap-x-[10px] gap-y-[24px] justify-start flex-wrap">
          <PriorityItem
            v-for="(data, idx) in priorityList"
            :key="idx"
            :data="data"
            :itemPriority="formState.warningLevel"
            @click="changePriority(data)"
          />
        </div>
        <span class="text-sm text-ems-main2 mt-1" v-if="priorityRequired">{{
          t('group-management.priority-required')
        }}</span>
      </a-form-item>
      <div
        v-if="!isCreateSubGroup"
        class="h-[1px] w-full bg-ems-gray600 !my-10"
      ></div>
      <a-form-item name="parentName" v-if="!isCreateSubGroup">
        <span class="text-base font-semibold text-ems-gray200 mb-[15px]">{{
          t('group-management.parentName')
        }}</span>
        <SelectGroup
          :model-value="formState.parent"
          @update:model-value="updateVl"
          :placeholder="t('group-management.parentName')"
        />
      </a-form-item>
      <a-form-item class="!mt-[80px]">
        <div class="flex justify-center">
          <ClipButton class="flex items-center !px-[25px]" :type="'primary'" >
            <span>{{ t('group-management.create-btn') }}</span>
          </ClipButton>
        </div>
      </a-form-item>
    </a-form>
  </div>
</template>
<script setup>
import { i18n } from '@/main';
import { computed, defineEmits, ref, reactive } from 'vue';
import ClipButton from '@/components/buttons/ClipButton.vue';
import SelectGroup from '@/components/select-group/SelectGroup.vue';
import PriorityItem from './PriorityItem.vue';
import { maxlength, required } from '@/util/validation';
import { WARNING_LEVEL } from '@/config/Constant.js';
import { useStore } from 'vuex';
import {trim} from '@/util/common-utils';
const { t } = i18n.global;
const { state, dispatch } = useStore();
const priorityList = reactive(state.ioGroup.warningLevel);

const emit = defineEmits(['onSearchAdvanced']);
const changePriority = (priority) => {
  formState.warningLevel = priority.id;
};
const updateVl = (newValue) => {
  formState.parent = newValue;
};
const rules = ref({
  name: [
    maxlength(255, 'group-management.name'),
    required('group-management.name'),
  ],
  description: [maxlength(255, 'group-management.desc')],
  warningLevel: [required('group-management.priority')],
  parent: [],
});
const priorityRequired = ref(false);
const handleSubmit = async () => {
  if (props.isCreateSubGroup) {
    formState.parent = props.parent;
  }
  formState.name = trim(formState.name);
  formState.description = trim(formState.description);
  let res = await dispatch('ioGroup/create', formState);
  if (res) {
    formState = reactive({
      name: '',
      description: '',
      warningLevel: null,
      parent: null,
    });
    let promises = [
      dispatch('ioGroup/getTreeGroup'),
      dispatch('ioGroup/getAllGroup'),
    ];
    await Promise.all(promises);
    dispatch('ioGroup/selectGroup', res.id);
    emit('onSearchAdvanced');
  }
};
let formState = reactive({
  name: '',
  description: '',
  warningLevel: null,
  parent: null,
});

const props = defineProps({
  isCreateSubGroup: {
    type: Boolean,
    default: false,
  },
  parent: {
    type: Object,
    default: () => {},
  },
});
</script>

<style>
.create-group .ant-select {
  height: 38px;
}
.create-group .ant-select-selector {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(43, 42, 58, var(--tw-bg-opacity)) !important;
  border: none !important;
  border-radius: 0 !important;
}
.create-group .ant-input-sm {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(43, 42, 58, var(--tw-bg-opacity)) !important;
  --tw-border-opacity: 1 !important;
  border-color: rgba(112, 111, 128, var(--tw-border-opacity)) !important;
}
.create-group .ant-input {
  --tw-bg-opacity: 1;
  background-color: rgba(43, 42, 58, var(--tw-bg-opacity));
  padding-left: 11px;
  padding-right: 11px;
  padding-top: 5px;
  padding-bottom: 5px;
  --tw-text-opacity: 1;
  color: rgba(233, 233, 233, var(--tw-text-opacity));
  border: none !important;
}
.create-group .ant-input::placeholder {
  --tw-text-opacity: 1;
  color: rgba(112, 111, 128, var(--tw-text-opacity));
}
</style>
